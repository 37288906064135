// libs/translation/src/lib/translation.service.ts
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppTranslationService {
  private currentLanguageSubject: BehaviorSubject<string> = new BehaviorSubject<string>('cz');
  currentLanguage$: Observable<string> = this.currentLanguageSubject.asObservable();

  constructor(private translateService: TranslateService) {}

  setDefaultLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }

  useLanguage(lang: string): void {
    this.translateService.use(lang);
    this.currentLanguageSubject.next(lang);
  }

  getCurrentLanguage(): string {
    return this.translateService.currentLang;
  }

  onLanguageChange(): Observable<string> {
    return this.translateService.onLangChange.pipe(map(event => event.lang));
  }

  loadTranslations(lang: string): Promise<void> {
    return this.translateService.use(lang).toPromise();
  }
}
