<!-- Loading bar -->
<!-- <fuse-loading-bar></fuse-loading-bar> -->

<!-- Navigation -->
<fuse-vertical-navigation
    class="light print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex justify-between w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img class="w-30" src="assets/images/logo/logo-180.png" />
            </div>
            <div>
                <div class="text-sm text-slate-300 text-right">{{projectVersion}}</div>
                <div class="flex items-center" (click)="changeLanguage()">
                    <div class="text-sm text-slate-300 mr-2">{{currentLanguage}}</div>
                    <mat-icon *ngIf="currentLanguage === 'cz'" class="text-slate-300 scale-75" [svgIcon]="'feather:toggle-left'"></mat-icon>
                    <mat-icon *ngIf="currentLanguage === 'en'" class="text-slate-300 scale-75" [svgIcon]="'feather:toggle-right'"></mat-icon>
                </div>
            </div>

        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <!-- <div class="relative w-24 h-24">

                <mat-icon
                    class="icon-size-24"
                    [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div> -->
            <!-- <div class="flex flex-col items-center justify-center w-full mt-6">
                <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user.userName}} {{user.userName}}
                </div>
                <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user.userName}}
                </div>
            </div> -->
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div
        class="relative flex flex-0 items-center justify-between w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
    >
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>

        <div class="flex items-center">
            <user [showAvatar]="false"></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <router-outlet *ngIf="true"></router-outlet>
    </div>
</div>
