import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CounterReaderService {
    /**
     * Constructor
     */
    constructor(private readonly _httpClient: HttpClient) {
        this.refreshData();
    }

    public refreshData() {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this._httpClient.get('http://localhost:8001/get_counter_id', { headers: { 'Access-Control-Allow-Origin': '*' } })
            .subscribe(
            response => {
                if (response) {
                    localStorage.setItem('counterId', response.toString());
                }
            },
            () => {
                localStorage.removeItem('counterId');
                localStorage.setItem('counterId', '0');
            }
        );
    }

    /**
     * Setter & getter for access token
     */
    set counterId(counterId: string) {
        localStorage.setItem('counterId', counterId);
    }

    get counterId(): string {
        // @ts-ignore
        return localStorage.getItem('counterId') && localStorage.getItem('counterId') !== 'undefined'
            ? localStorage.getItem('counterId')
            : '';
    }
}
