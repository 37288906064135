import { NgModule, Optional, SkipSelf } from '@angular/core';
import { IconsModule } from './icons/icons.module';
import { TranslocoCoreModule } from './transloco/transloco.module';
import { AuthModule } from 'brinks';

@NgModule({
    imports: [AuthModule, IconsModule, TranslocoCoreModule],
})
export class CoreModule {
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error(
                'CoreModule has already been loaded. Import this module in the AppModule only.'
            );
        }
    }
}
