import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject, switchMap, tap } from 'rxjs';
import { Navigation } from './navigation.types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccessService } from 'brinks-openapi';
import { FuseNavigationItem } from 'fuseui';
import { environment } from '../../../../environments/environment';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> =
        new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _accessService: AccessService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        if(!environment.development) {
            return this._accessService.accessMenumenuIdGet('1').pipe(
                untilDestroyed(this),
                tap((dataNavigation) => {
                    this._navigation.next(
                        {
                            compact: dataNavigation as FuseNavigationItem[],
                            default: dataNavigation as FuseNavigationItem[],
                            futuristic: dataNavigation as FuseNavigationItem[],
                            horizontal: dataNavigation as FuseNavigationItem[],
                        } as Navigation
                    );
                }),
                switchMap((dataNavigation) => of({
                    compact: dataNavigation as FuseNavigationItem[],
                    default: dataNavigation as FuseNavigationItem[],
                    futuristic: dataNavigation as FuseNavigationItem[],
                    horizontal: dataNavigation as FuseNavigationItem[],
                } as Navigation)),
            );
        }else{
            return this._httpClient.get<Navigation>('api/common/navigation').pipe(
                tap((navigation) => {
                    this._navigation.next(navigation);
                })
            );
        }
    }
}
