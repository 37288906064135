/* tslint:disable:max-line-length */
import { FuseNavigationItem } from "fuseui";

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'menu.dashboard',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard',
        role: 'other',
    },

    {
        id: 'banks',
        title: 'menu.banks',
        type: 'basic',
        icon: 'heroicons_outline:office-building',
        link: '/banks',
        role: 'other',
    },

    {
        id: 'app-main-cashier',
        title: 'menu.config',
        subtitle: '',
        type: 'group',
        children: [
            {
                id: 'atms',
                title: 'menu.atms',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: '/atms',
            },
            {
                id: 'sets',
                title: 'menu.sets',
                type: 'basic',
                icon: 'heroicons_outline:view-grid',
                link: '/sets',
                role: 'other',
            },
            {
                id: 'cassettes',
                title: 'menu.cassettes',
                type: 'basic',
                icon: 'heroicons_outline:archive',
                link: '/cassettes',
                role: 'other',
            },
        ],
    },

    {
        id: 'app-main-cashier',
        title: 'menu.app-main-cashier',
        subtitle: 'menu.app-main-cashier.subtitle',
        type: 'group',
        children: [
            {
                id: 'overview',
                title: 'menu.overview',
                type: 'basic',
                icon: 'heroicons_outline:chart-bar',
                link: '/overview',
            },
            {
                id: 'orders',
                title: 'menu.orders',
                type: 'basic',
                icon: 'heroicons_outline:clipboard',
                link: '/orders',
            },
            {
                id: 'order-create',
                title: 'menu.atms-out.new',
                type: 'basic',
                icon: 'heroicons_outline:plus-circle',
                link: '/order-create',
            },
            {
                id: 'batches',
                title: 'menu.batches',
                type: 'basic',
                icon: 'heroicons_outline:shopping-bag',
                link: '/batches',
            },
            {
                id: 'deposit',
                title: 'menu.deposit',
                type: 'basic',
                icon: 'heroicons_outline:lock-open',
                link: '/deposit',
            },
            {
                id: 'money-out',
                title: 'menu.money-out',
                type: 'basic',
                icon: 'feather:dollar-sign',
                link: '/money-out',
            },
        ],
    },
    {
        id: 'app-cashier-in',
        title: 'menu.app-cashier-in',
        subtitle: 'menu.app-cashier-in.subtitle',
        type: 'group',
        children: [
            {
                id: 'user-batches',
                title: 'menu.app-cashier-in.user-batches',
                type: 'basic',
                icon: 'heroicons_outline:bookmark',
                link: '/user-batches',
            },
        ],
    },
    {
        id: 'app-cashier-out',
        title: 'menu.atms-out',
        subtitle: 'menu.atms-out.subtitle',
        type: 'group',
        children: [
            {
                id: 'atms-out-list',
                title: 'menu.atms-out-list',
                type: 'basic',
                icon: 'heroicons_outline:clipboard',
                link: '/atms-out/list',
            },
            {
                id: 'atms-out',
                title: 'menu.atms-out',
                type: 'basic',
                icon: 'heroicons_outline:bookmark',
                link: '/atms-out/wizard',
            },
            {
                id: 'workplace',
                title: 'menu.workplace.money-out',
                type: 'basic',
                icon: 'feather:upload',
                link: '/workplace/request',
            },
            {
                id: 'workplace',
                title: 'menu.workplace.requests',
                type: 'basic',
                icon: 'feather:check-square',
                link: '/workplace/list',
            },
            {
                id: 'withdrawals',
                title: 'menu.workplace.withdrawals',
                type: 'basic',
                icon: 'feather:clipboard',
                link: '/withdrawal-check',
            },
        ],
    },
    {
        id: 'app-cnb',
        title: 'menu.app-cnb',
        subtitle: 'menu.app-cnb.subtitle',
        type: 'group',
        children: [
            {
                id: 'cnb',
                title: 'menu.app-cnb.cnb-list',
                type: 'basic',
                icon: 'feather:archive',
                link: '/cnb/list',
            },
            {
                id: 'cnb-empty',
                title: 'menu.app-cnb.cnb-empty',
                type: 'basic',
                icon: 'heroicons_outline:arrow-up-on-square',
                link: '/cnb/empty',
            },
            {
                id: 'cnb-fill',
                title: 'menu.app-cnb.cnb-fill',
                type: 'basic',
                icon: 'heroicons_outline:arrow-down-on-square',
                link: '/cnb/fill',
            },
        ],
    },
    {
        id: 'app-relay',
        title: 'menu.app-relay',
        subtitle: 'menu.app-relay.subtitle',
        type: 'group',
        children: [
            {
                id: 'relay',
                title: 'menu.app-relay.relay-list',
                type: 'basic',
                icon: 'feather:list',
                link: '/relay/list',
            },
            {
                id: 'relay-close',
                title: 'menu.app-relay.relay-close',
                type: 'basic',
                icon: 'feather:check-square',
                link: '/relay/close',
            },
        ],
    },
    {
        id: 'app-reports',
        title: 'menu.app-report',
        subtitle: 'menu.app-report.subtitle',
        type: 'group',
        children: [
            {
                id: 'report-test',
                title: 'menu.app-report.report-test',
                type: 'basic',
                icon: 'feather:archive',
                link: '/report/test?id=1',
                externalLink: true,
            },
            {
                id: 'report-test',
                title: 'menu.app-report.report-test',
                type: 'basic',
                icon: 'feather:archive',
                link: '/report/test?id=2',
                externalLink: true,
            },
            {
                id: 'report-test',
                title: 'menu.app-report.report-day',
                type: 'basic',
                icon: 'feather:archive',
                link: '/report/test?id=day2',
                externalLink: true,
            },
        ],
    },
    {
        id: 'app-group',
        title: 'menu.app-group',
        subtitle: 'menu.app-group.subtitle',
        type: 'group',
        children: [
            {
                id: 'users',
                title: 'menu.users',
                type: 'basic',
                icon: 'heroicons_outline:users',
                link: '/users',
            },
            {
                id: 'region',
                title: 'menu.regions',
                type: 'basic',
                icon: 'heroicons_outline:map',
                link: '/region',
            },
            {
                id: 'reset',
                title: 'menu.reset',
                type: 'basic',
                icon: 'heroicons_outline:backspace',
                link: '/reset',
            },
            {
                id: 'change-szh',
                title: 'menu.change-szh',
                type: 'basic',
                icon: 'heroicons_outline:backspace',
                link: '/change-szh',
            },
            {
                id: 'support',
                title: 'menu.support',
                type: 'basic',
                icon: 'heroicons_outline:question-mark-circle',
                link: '/support',
            },
            {
                id: 'changelog',
                title: 'menu.changelog',
                type: 'basic',
                icon: 'heroicons_outline:newspaper',
                link: '/changelog',
            },
        ],
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Přehled',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard',
    },
    {
        id: 'users',
        title: 'Přehled uživatelů',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/users',
    },
    // {
    //     id      : 'misc-group',
    //     title   : 'Ostatní',
    //     type    : 'group',
    //     role    : 'other',
    //     children: [
    //         {
    //             id   : 'balance',
    //             title: 'Transakce',
    //             type : 'basic',
    //             icon : 'heroicons_outline:currency-dollar',
    //             link : '/balance'
    //         },
    //
    //     ]
    // },
    {
        id: 'app-group',
        title: 'Aplikace Brinks',
        subtitle: 'Vše co potřebujete vědět',
        type: 'group',
        children: [
            {
                id: 'support',
                title: 'Podpora',
                type: 'basic',
                icon: 'heroicons_outline:question-mark-circle',
                link: '/support',
            },
            {
                id: 'changelog',
                title: 'Changelog',
                type: 'basic',
                icon: 'heroicons_outline:newspaper',
                link: '/changelog',
            },
        ],
    },
];
