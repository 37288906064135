import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseMediaWatcherService, FuseNavigationService, FuseVerticalNavigationComponent } from 'fuseui';
import { Navigation } from '../../navigation/navigation.types';
import { NavigationService } from '../../navigation/navigation.service';
import { UserService } from '../../../../../../../../apps/fuse/src/app/core/user/user.service';
import { UserInfo } from 'brinks-openapi';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import packageJson from 'package.json';
import { TranslateService } from '@ngx-translate/core';
import { AppTranslationService } from '../../../translation.service';

@UntilDestroy()
@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent implements OnInit {
    isScreenSmall?: boolean;
    navigation?: Navigation;
    user?: UserInfo;

    currentLanguage = 'cz';

    projectVersion = '0';
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private translate: AppTranslationService
    ) {
        this.projectVersion = packageJson.version;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$.pipe(untilDestroyed(this)).subscribe((navigation: Navigation) => {
            this.navigation = navigation;
        });

        // Subscribe to the user service
        this._userService.user$.pipe(untilDestroyed(this)).subscribe((user: UserInfo) => {
            this.user = user;
        });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$.pipe(untilDestroyed(this)).subscribe(({ matchingAliases }) => {
            // Check if the screen is small
            this.isScreenSmall = !matchingAliases.includes('md');
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    changeLanguage(): void {
        if(this.currentLanguage === 'en'){
            this.translate.useLanguage('cz');
            this.currentLanguage = 'cz';
        }else{
            this.translate.useLanguage('en');
            this.currentLanguage = 'en';
        }
    }
}
