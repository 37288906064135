import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { UserComponent } from './user.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule}  from '@angular/material/badge';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [UserComponent],
    imports: [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatBadgeModule,
        TranslateModule,
    ],
    exports: [UserComponent],
})
export class UserModule {}
