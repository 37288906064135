import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UserService } from '../../../../../../../apps/fuse/src/app/core/user/user.service';

import { AuthService } from '../../../auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessagesResponse, UserInfo } from 'brinks-openapi';
import { NotificationService } from './notification.service';
import { interval, Subscription, switchMap } from 'rxjs';
import { globalSettings } from '../../../brinks.settings';
import { CounterReaderService } from '../../../auth';

@UntilDestroy()
@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
})
export class UserComponent implements OnInit {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar = true;
    user?: UserInfo;

    notifications!: MessagesResponse;
    userStatus!: string | null | undefined;
    messages!: any;
    total!: number | undefined;

    notificationSubscription!: Subscription;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private _notificationService: NotificationService,
        private _authService: AuthService,
        private _counterService: CounterReaderService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user changes
        this._userService.user$.pipe(untilDestroyed(this)).subscribe((user: UserInfo) => {
            this.user = user;

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });

        this.notificationSubscription = interval(globalSettings.notificationTimeout)
            .pipe(
                switchMap(() => this._notificationService.getNotifications()) // Switch to new observable on every interval
            )
            .subscribe(data => {
                this.notifications = data;
                this.messages = data.messages;
                this.total = data.total;
                this.userStatus = data.userStatus;
                // this._counterService.refreshData();
                this._changeDetectorRef.markForCheck();
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign out
     */
    signOut(): void {
        this._authService.signOut().subscribe(() => {
            this._router.navigate(['/sign-out']);
        });
    }

    userProfile(): void {
        this._router.navigate(['/users/profile']);
    }

    onNotificationsClose(): void {
        //
    }

    closeNotification(id: number): void {
        this._notificationService.closeNotifications(id).subscribe(()=> {
            this._notificationService.getNotifications().subscribe((data) => {
                this.notifications = data;
                    this.messages = data.messages;
                    this.total = data.total;
                    this._changeDetectorRef.markForCheck();
            })
        });
    }

    get counterId(): string {

        if( localStorage.getItem('counterId') && localStorage.getItem('counterId') !== 'undefined' && localStorage.getItem('counterId') !== "0"){
            return String(localStorage.getItem('counterId'));
        }else{
            return '×';
        }

    }
}
