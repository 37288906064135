import { NgModule } from '@angular/core';
import { ApiModule, BASE_PATH } from 'brinks-openapi';
import { AuthModule } from 'brinks';
import { TcUtilsModule } from 'tcdesign';
import { environment } from '../environments/environment';

@NgModule({
    imports: [
        AuthModule,
        ApiModule,
        TcUtilsModule,
    ],
    providers: [{ provide: BASE_PATH, useValue: environment.apiUrl }],
    exports: [],
})
export class BrinksModule {}
