import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdministratorService } from 'brinks-openapi';
import { GlobalParams } from '../model/globalParams';
import { globalSettings } from '../../brinks.settings';

@Injectable({
    providedIn: 'root',
})
export class GlobalParamsService {
    private storeGlobalParams!: GlobalParams;
    private storeGlobalSettings!: any;
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private readonly _adminService: AdministratorService) {
        this.getGlobalParams();
    }

    storeParams() {
        this._adminService.administratorGlobalParamsGet().subscribe(dataParams => {
            dataParams.boolParams?.list?.map(r => {
                switch (r.name) {
                    case 'ContainerRecalculate': {
                        this.storeGlobalParams.containerRecalculate = r.value !== undefined ? r.value : true;
                        return;
                    }
                    default: {
                        return;
                    }
                }
            });
            this.saveGlobalParams();
        });

        this.saveGlobalSettings();
    }

    private getGlobalParams() {
        const localStorageDataSettings = localStorage.getItem('globalParams');
        this.storeGlobalParams = JSON.parse(localStorageDataSettings ? localStorageDataSettings : '{}');
    }

    private getGlobalSettings() {
        const localStorageDataParams = localStorage.getItem('globalSettings');
        this.storeGlobalSettings = JSON.parse(localStorageDataParams ? localStorageDataParams : '{}');
    }

    private saveGlobalParams() {
        localStorage.setItem('globalParams', JSON.stringify(this.storeGlobalParams));
    }

    private saveGlobalSettings() {
        this.storeGlobalSettings = globalSettings;
        localStorage.setItem('globalSettings', JSON.stringify(globalSettings));
    }

    public isContainerRecalculate(): boolean {
        return this.storeGlobalParams.containerRecalculate;
    }
}
