import { Route } from "@angular/router";
import { InitialDataResolver } from "./app.resolvers";
import { LayoutComponent } from "brinks";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'sign-in' },

    // Auth routes for guests
    {
        path: '',
        //   canActivate: [NoAuthGuard],
        //   canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-in',
                loadChildren: () =>
                    import(
                        'libs/brinks/src/lib/auth/sign-in/sign-in.module'
                    ).then((m) => m.AuthSignInModule),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import(
                        'libs/brinks/src/lib/auth/sign-up/sign-up.module'
                    ).then((m) => m.AuthSignUpModule),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        // canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import(
                        'libs/brinks/src/lib/auth/sign-out/sign-out.module'
                    ).then((m) => m.AuthSignOutModule),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'libs/brinks/src/lib/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        //        canActivate: [NoAuthGuard],
        //        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'dashboard',
                loadChildren: () =>
                    import(
                        'libs/brinks/src/lib/dashboard/dashboard.module'
                    ).then((m) => m.DashboardModule),
            },
            {
                path: 'showcase',
                loadChildren: () =>
                    import('libs/brinks/src/lib/showcase/showcase.module').then(
                        (m) => m.ShowcaseModule
                    ),
            },
            {
                path: 'users',
                loadChildren: () =>
                    import('libs/brinks/src/lib/users/users.module').then(
                        (m) => m.UsersModule
                    ),
            },
            {
                path: 'region',
                loadChildren: () =>
                    import('libs/brinks/src/lib/region/region.module').then(
                        (m) => m.RegionModule
                    ),
            },
            {
                path: 'cassettes',
                loadChildren: () =>
                    import('libs/brinks/src/lib/cassette/cassette.module').then(
                        (m) => m.CassetteModule
                    ),
            },
            {
                path: 'banks',
                loadChildren: () =>
                    import('libs/brinks/src/lib/bank/bank.module').then(
                        (m) => m.BankModule
                    ),
            },
            {
                path: 'sets',
                loadChildren: () =>
                    import('libs/brinks/src/lib/set/sets.module').then(
                        (m) => m.SetsModule
                    ),
            },
            {
                path: 'atms',
                loadChildren: () =>
                    import('libs/brinks/src/lib/atms/atm.module').then(
                        (m) => m.AtmModule
                    ),
            },
            {
                path: 'overview',
                loadChildren: () =>
                    import('libs/brinks/src/lib/overview/overview.module').then(
                        (m) => m.OverviewModule
                    ),
            },
            {
                path: 'orders',
                loadChildren: () =>
                    import('libs/brinks/src/lib/orders/orders.module').then(
                        (m) => m.OrdersModule
                    ),
            },
            {
                path: 'deposit',
                loadChildren: () =>
                    import('libs/brinks/src/lib/deposit/deposit.module').then(
                        (m) => m.DepositModule
                    ),
            },
            {
                path: 'batches',
                loadChildren: () =>
                    import('libs/brinks/src/lib/batches/batches.module').then(
                        (m) => m.BatchesModule
                    ),
            },
            {
                path: 'reset',
                loadChildren: () =>
                    import('libs/brinks/src/lib/reset/reset.module').then(
                        (m) => m.ResetModule
                    ),
            },
            {
                path: 'user-batches',
                loadChildren: () =>
                    import('libs/brinks/src/lib/user-batches/user-batches.module').then(
                        (m) => m.UserBatchesModule
                    ),
            },
            {
                path: 'atms-out',
                loadChildren: () =>
                    import('libs/brinks/src/lib/atms-out/atms-out.module').then(
                        (m) => m.AtmsOutModule
                    ),
            },
            {
                path: 'order-create',
                loadChildren: () =>
                    import('libs/brinks/src/lib/order-create/order-create.module').then(
                        (m) => m.OrderCreateModule
                    ),
            },
            {
                path: 'money-out',
                loadChildren: () =>
                    import('libs/brinks/src/lib/money-out/money-out.module').then(
                        (m) => m.MoneyOutModule
                    ),
            },
            {
                path: 'cnb',
                loadChildren: () =>
                    import('libs/brinks/src/lib/cnb/cnb.module').then(
                        (m) => m.CnbModule
                    ),
            },
            {
                path: 'report',
                loadChildren: () =>
                    import('libs/brinks/src/lib/report/report.module').then(
                        (m) => m.ReportModule
                    ),
            },
            {
                path: 'withdrawal-check',
                loadChildren: () =>
                    import('libs/brinks/src/lib/withdrawal-check/withdrawal-check.module').then(
                        (m) => m.WithdrawalCheckModule
                    ),
            },
            {
                path: 'workplace',
                loadChildren: () =>
                    import('libs/brinks/src/lib/workplace/workplace.module').then(
                        (m) => m.WorkplaceModule
                    ),
            },
            {
                path: 'relay',
                loadChildren: () =>
                    import('libs/brinks/src/lib/relay/relay.module').then(
                        (m) => m.RelayModule
                    ),
            },
            {
                path: 'change-szh',
                loadChildren: () =>
                    import('libs/brinks/src/lib/change-szh/change-szh.module').then(
                        (m) => m.ChangeSzhModule
                    ),
            },
        ],
    },
];
