import { NgModule } from '@angular/core';
import { ApiModule } from 'brinks-openapi';
import { TcUtilsModule } from 'tcdesign';
import { GlobalParamsService } from './services/global.params.service';

@NgModule({
    imports: [ApiModule, TcUtilsModule],
    providers: [GlobalParamsService],
    exports: [],
})
export class SharedModule {}
