import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UserLockDialogType } from '../dialog.types';
import { AccessService, CounterMachine, UsersService } from 'brinks-openapi';
import { Observable, tap } from 'rxjs';
import { UserService } from 'apps/fuse/src/app/core/user/user.service';
import { UserLockDialogService } from '../dialog.service';

@Component({
    selector: 'fuse-confirmation-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DialogComponent{
    form: FormGroup;
    // fields: string[] = ['note','test'];
    // $counters!: Observable<Array<CounterMachine>>



    constructor(
        @Inject(MAT_DIALOG_DATA) public data: UserLockDialogType,
        public _usersService: UsersService,
        public _userService: UserService,
        public _dialogService: UserLockDialogService,
        public _accessService: AccessService,
        private formBuilder: FormBuilder,
    ) {
        this.form = this.formBuilder.group({
            pin: '',
          });
    }

    public submit(): void{
        this._accessService.getAccess().subscribe((data) => {
            this._usersService.userUnblockUserIdPost(data.userId, this.form.value.pin).subscribe(() => {
                    this._dialogService.close();
                }
            )
        })

    }


}
