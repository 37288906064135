import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TcErrorModule} from './error/tc-error.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TcErrorModule
  ],
})
export class TcUtilsModule { }
