import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmountPipe } from './tc-amount/tc-amount-pipe';
import { ThousandsPipe } from './tc-thousands/tc-thousands.pipe';


@NgModule({
  declarations: [
      AmountPipe,
      ThousandsPipe
  ],
  imports: [
    CommonModule
  ],
    exports: [
        AmountPipe,
        ThousandsPipe
    ]
})
export class TcPipeModule { }
