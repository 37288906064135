<ng-container *ngIf="length && pageSize < length">
  <ng-container *ngIf="type == 'full'">
    <mat-paginator
        [pageSize]="pageSize" [length]="length" (page)="output($event.pageIndex)">
    </mat-paginator>
  </ng-container>

  <ng-container *ngIf="type == 'basic'">
    <div class="px-4 py-2">
      <ng-container *ngIf="previous !== undefined">
        <button class="px-4 py-2 text-primary border border-primary rounded-full" type="button" (click)='output(previous)'>{{'tc-design.paginator.previous' | translate}}</button>
      </ng-container>
      <ng-container *ngFor="let y of ' '.repeat(pageCount).split(''), let x = index">
        <button class="p-4" [ngClass]="{'text-primary font-bold': x == current,'text-secondary': x !== current}" type="button" (click)='output(x)'>{{x+1}}</button>
      </ng-container>
      <ng-container *ngIf="next !== undefined">
        <button class="px-4 py-2 text-primary border border-primary rounded-full" type="button" (click)='output(next)'>{{'tc-design.paginator.next' | translate}}</button>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="type == 'input'">
    <div class="flex justify-end">
        <div class="px-8 py-2">
        <ng-container *ngIf="previous !== undefined">
            <button class="px-4 py-2 text-primary border border-primary rounded-full" type="button" (click)='output(previous)'>{{'tc-design.paginator.previous' | translate}}</button>
        </ng-container>
        <input class="no-spinner w-12 border text-primary border-primary text-center rounded-full ml-1" [class.text-red-600]="err" type="number" max="{{pageCount}}" [value]="current+1" (change)="output($event.target.valueAsNumber-1)" (keydown)="dismisErr()" inputmode="numeric"> of {{pageCount}}
        <ng-container *ngIf="next !== undefined">
            <button class="px-4 py-2 text-primary border border-primary rounded-full" type="button" (click)='output(next)'>{{'tc-design.paginator.next' | translate}}</button>
        </ng-container>
        </div>
    </div>
  </ng-container>

  <ng-container *ngIf="type == 'compact'">
    <div class="px-4 py-2">
      <ng-container *ngIf="previous !== undefined">
        <button class="px-4 py-2 text-primary border border-primary rounded-full" type="button" (click)='output(previous)'>{{'tc-design.paginator.previous' | translate}}</button>
      </ng-container>
      <span class="px-8">
      {{current+1}} / {{pageCount}}
      </span>
      <ng-container *ngIf="next !== undefined">
        <button class="px-4 py-2 text-primary border border-primary rounded-full" type="button" (click)='output(next)'>{{'tc-design.paginator.next' | translate}}</button>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
