<div class="flex items-center">

    <!-- Button -->
    <button class="flex flex-row items-center" [matMenuTriggerFor]="userActions">
        <div class="flex flex-col items-end">
            <div class="mr-2 text-sm">{{ user.userName }}</div>
            <div class="mr-2 font-bold">{{ userStatus }}</div>
        </div>

        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </button>

    <button class="ml-4 mt-1" [matMenuTriggerFor]="notifications">
        <mat-icon matBadge="{{total}}" [matBadgeColor]="total === 0 ? 'primary' : 'warn'" [svgIcon]="'feather:bell'"></mat-icon>
    </button>

    <mat-icon class="ml-4" matBadge="{{counterId}}" [matBadgeColor]="counterId === '' ? 'primary' : 'warn'" [svgIcon]="'heroicons_outline:calculator'"></mat-icon>
</div>


<mat-menu class="min-w-96 p-0 notification-dropdown rounded-md" [xPosition]="'before'" #notifications="matMenu" (closed)="onNotificationsClose()">
    <div class="text-primary bg-white border-b border-primary h-10 flex items-center justify-center">
        {{ 'notifications.dropdown.title' | translate }}
    </div>

    <ng-container *ngIf="messages">
        <div *ngFor="let message of messages;" (click)="$event.stopPropagation();">
            <div class="flex flex-col hover:bg-blue-50">
                <div class="border-b p-4 flex justify-between">
                    <div class="flex items-center">
                        <span class="p-2 rounded-full h-10 w-10 bg-orange-600 mr-4"><mat-icon class="text-white" [svgIcon]="'feather:bell'"></mat-icon></span>
                        <div class="flex flex-col justify-center">
                            <span>{{message.title | translate}}</span>
                            <div class="text-sm" *ngFor="let line of message.message;">{{line}}</div>
                        </div>
                    </div>
                    <div class="cursor-pointer" (click)="closeNotification(message.id)">
                        <mat-icon [svgIcon]="'feather:x'"></mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</mat-menu>

<mat-menu [xPosition]="'before'" #userActions="matMenu" class="min-w-72">
    <div mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>{{'layout.logged-in' | translate}}</span>
            <span class="mt-1.5 text-md font-medium">
                <strong>{{ user.userName }}</strong>
            </span>
        </span>
    </div>
    <mat-divider class="my-2"></mat-divider>
    <div mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>{{'layout.state' | translate}}</span>
            <span *ngIf="userStatus" class="mt-1.5 text-md font-medium">
                <strong>{{ userStatus }}</strong>
            </span>
        </span>
    </div>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item
            (click)="userProfile()">
            <mat-icon [svgIcon]="'heroicons_outline:user'"></mat-icon>
        <span>{{'layout.profile' | translate}}</span>
    </button>
    <!-- <button mat-menu-item
            (click)="settings()">
        <i class="far mr-2 fa-cog"></i>
        <span>Nastavení</span>
    </button> -->
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <i class="far mr-2 fa-sign-out-alt"></i>
        <span>{{'layout.logout' | translate}}</span>
    </button>
</mat-menu>
