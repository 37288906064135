import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousands'
})
export class ThousandsPipe implements PipeTransform {
    transform(value: number): string {

        const amount = new Intl.NumberFormat('cs-CZ', {
            //style: 'currency',
            //currency: currency,
            maximumFractionDigits: 0

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return amount.format(value);
    }
}
