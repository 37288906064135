import {Component, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppTranslationService } from 'brinks';

@Component({
    selector: 'tc-paginator',
    templateUrl: './tc-paginator.component.html',
    styleUrls: ['./tc-paginator.component.css'],
})
export class TcPaginatorComponent {

    @Input() length?: number;
    @Input() type?: string = 'basic';
    @Input() pageSize?: number = 30;
    @Output() onClick = new EventEmitter<any>();

    pageCount: number;
    current: number;
    previous?: number;
    next?: number;
    err = false;

    constructor(
        private translateService: TranslateService,
        private appTranslationService: AppTranslationService
    ) {}

    ngOnInit() {
        this.appTranslationService.onLanguageChange().subscribe(lang => {
            this.translateService.use(lang);
        });

      this.pageCount = Math.ceil(this.length/this.pageSize);
      this.current = 0;
      this.previous = undefined;
      this.next = 1;
    }

    dismisErr(): void {
        this.err = false;
    }

    output(page){

        if(page+1 <= this.pageCount){

            this.err = false;

            this.current = page;

            if( page !== 0 ){
                this.previous = page - 1;
            }else{
                this.previous = undefined;
            }

            if( page !== (this.pageCount-1) ){
                this.next = page + 1;
            }else{
                this.next = undefined;
            }

            this.onClick.emit(page)
        }else{
            this.err = true;
        }

    }

    ngOnChanges() {
      this.pageCount = Math.ceil(this.length/this.pageSize);
      this.current = 0;
      this.previous = undefined;
      this.next = 1;
    }

}
