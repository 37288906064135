<div class="rounded-none min-w-128">

    <div class="flex justify-between">

        <div class="text-xl font-bold">{{ 'user-lock.title' | translate }}</div>

    </div>

    <div>{{ 'user-lock.message' | translate }}</div>

    <form [formGroup]="form" (ngSubmit)="submit()">

        <div class="mt-4">
          <label for="name">
            {{"user-lock.pin" | translate}}
          </label>
          <div class="w-full">
            <mat-form-field appearance="fill" class="w-full">
            <input autocomplete="off" matInput class="px-4 py-2 focused-input border rounded-lg"
            [formControlName]="'pin'"
            />
            </mat-form-field>
        </div>
        </div>

        <button mat-stroked-button class="button" type="submit">{{"user-lock.submit" | translate}}</button>

      </form>

</div>
