import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { AccessService, UserInfo } from 'brinks-openapi';
import { UserLockDialogService, UserLockDialogType } from '../../../../../../libs/brinks/src/lib/user-lock';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private _user: ReplaySubject<UserInfo> = new ReplaySubject<UserInfo>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private readonly _accessService: AccessService,
        private _userLockDialogService: UserLockDialogService,
        ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: UserInfo) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<UserInfo> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<UserInfo> {
        return this._accessService.getAccess().pipe(
            tap(user => {

                if(user.isBlocked){
                    const custonDialogConfig: UserLockDialogType = {
                        name: 'add-virtual',
                        identifier: 'new-cartridge',
                    };

                    const customDialog = this._userLockDialogService.open(custonDialogConfig);

                }

                this._user.next(user);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: UserInfo): Observable<any> {
        return this._httpClient.patch<UserInfo>('api/common/user', { user }).pipe(
            map(response => {
                this._user.next(response);
            })
        );
    }
}
