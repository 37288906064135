import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TcPaginatorComponent} from "./tc-paginator.component";
import {MatPaginatorModule} from "@angular/material/paginator";
import { LanguagesModule } from '../../../../../../brinks/src/lib/layout/common/languages/languages.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    declarations: [TcPaginatorComponent],
    imports: [
        CommonModule,
        MatPaginatorModule,
        LanguagesModule,
        TranslateModule
    ],
    exports: [
        TcPaginatorComponent
    ]
})
export class TcPaginatorModule {}


