import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { AuthService } from './auth.service';
import { AuthInterceptor } from './auth.interceptor';
import { SharedModule } from '../shared/shared.module';
import { CounterReaderService } from './counter-reader.service';

@NgModule({
    imports: [HttpClientModule, SharedModule, HttpClientJsonpModule],
    providers: [
        AuthService,
        CounterReaderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        }
    ],
})
export class AuthModule {}
