import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { merge } from 'lodash-es';
import { DialogComponent } from './dialog/dialog.component';
import { UserLockDialogType } from './dialog.types';

@Injectable()
export class UserLockDialogService {

    /**
     * Constructor
     */
    constructor(private _matDialog: MatDialog) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    open( data: UserLockDialogType
    ): MatDialogRef<DialogComponent> {
        // Merge the user config with the default config
        const userData = data;

        // Open the dialog
        return this._matDialog.open(DialogComponent, {
            data: userData,
            panelClass: 'custom-container',
            disableClose: true
        });
    }

    close(): void {
        this._matDialog.closeAll();
    }
}
