import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ExtraOptions, NoPreloading, RouterModule } from "@angular/router";
import { FuseConfigModule, FuseMockApiModule, FuseModule } from "fuseui";
import { appConfig } from "../../../../libs/brinks/src/lib/config/app.config";

import { AppComponent } from "./app.component";
import { appRoutes } from "./app.routing";
import { BrinksModule } from "../../../../libs/brinks/src/lib/brinks.module";
import { CoreModule } from "./core/core.module";
import { LayoutModule, UnauthorizedInterceptor } from "brinks";
import { mockApiServices } from "./mock-api";
import { HttpClient } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { UserLockModule } from "../../../../libs/brinks/src/lib/user-lock";
import { ToastrModule } from "ngx-toastr";
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";

const routerConfig: ExtraOptions = {
    preloadingStrategy: NoPreloading,
    scrollPositionRestoration: 'enabled',
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        UserLockModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        BrinksModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),
        // Core module of your application
        CoreModule,
        // Layout module of your application
        LayoutModule,
        ToastrModule.forRoot(),
        // Translate module
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        UnauthorizedInterceptor,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
          },
          {
            provide: Sentry.TraceService,
            deps: [Router],
          },
          {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
          },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(trace: Sentry.TraceService) {}
}
