import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessagesResponse, UsersService, AdministratorService } from 'brinks-openapi';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(
        private _httpClient: HttpClient,
        private readonly _usersService: UsersService,
        private _administratorService: AdministratorService
        ) {}

    getNotifications(): Observable<MessagesResponse> {
        return this._usersService.userMessagesGet();
    }

    closeNotifications(id: number): Observable<MessagesResponse> {
        return this._administratorService.administratorMessageAsReadMessageIdDelete(id);
    }

}
